import React from 'react';
import { Layout } from '../components/common';

const NotFoundPage = () => (
  <Layout>
    <div className="container">
      <article className="content text-center py-5">
        <h1 className="content-title mb-5">Page Not Found</h1>
      </article>
    </div>
  </Layout>
);

export default NotFoundPage;
